<template>
  <div>
    <slot name="activator" :triggerUpload="triggerUpload"></slot>
    <input ref="upload" type="file" @input="handleFileChange" class="d-none" />
    <v-dialog v-model="uploadDialog" hide-overlay persistent width="300">
      <v-card :color="error ? 'error' : 'primary'" dark>
        <v-card-text>
          <template v-if="!error">
            <span v-if="!error">Uploading...</span>
            <v-progress-linear
              v-if="!error"
              indeterminate
              color="white"
              class="mb-0"
            ></v-progress-linear>
          </template>
          <template v-else>
            <span v-html="error"></span>
          </template>
        </v-card-text>
        <v-card-actions v-if="error">
          <v-btn color="primary" text @click="closeDialog">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Papa from "papaparse";

export default {
  name: "InputCSV",
  props: {
    rowHandler: {
      type: Function,
      required: false,
    },
    onComplete: {
      type: Function,
      required: false,
    },
    useHeaders: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      uploadDialog: null,
      error: null,
    };
  },
  computed: {},
  created() {},
  methods: {
    closeDialog() {
      this.uploadDialog = false;
      this.error = null;
    },
    triggerUpload() {
      this.$refs.upload.click();
    },
    step(row) {
      this.rowHandler(row.data[0]);
    },
    handleFileChange(e) {
      this.$emit("change");
      const file = e.target.files[0];
      if (file) {
        this.uploadDialog = true;
        this.$emit("file-selected", file);
      }
      const options = {
        header: this.useHeaders,
        dynamicTyping: true,
        skipEmptyLines: true,
        worker: false,
        complete: (results) => {
          try {
            if (this.onComplete) this.onComplete(results.data);
          } catch (error) {
            this.error = error;
          }
          if (!this.error) this.uploadDialog = false;
        },
      };

      if (this.rowHandler)
        options.step = (row, parser) => {
          try {
            this.step(row);
          } catch (error) {
            this.error = error;
            parser.abort();
          }
        };
      Papa.parse(file, options);
    },
  },
};
</script>

<style></style>
